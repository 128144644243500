//Font Family
@font-face {
  font-family: 'Circular-Bold';
  src: url('../../fonts/CircularStd-Bold.eot');
  src: url('../../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CircularStd-Bold.woff2') format('woff2'),
  url('../../fonts/CircularStd-Bold.woff') format('woff'),
  url('../../fonts/CircularStd-Bold.ttf') format('truetype'),
  url('../../fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Book';
  src: url('../../fonts/CircularStd-Book.eot');
  src: url('../../fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CircularStd-Book.woff2') format('woff2'),
  url('../../fonts/CircularStd-Book.woff') format('woff'),
  url('../../fonts/CircularStd-Book.ttf') format('truetype'),
  url('../../fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Medium';
  src: url('../../fonts/CircularStd-Medium.eot');
  src: url('../../fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CircularStd-Medium.woff2') format('woff2'),
  url('../../fonts/CircularStd-Medium.woff') format('woff'),
  url('../../fonts/CircularStd-Medium.ttf') format('truetype'),
  url('../../fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Courier-New-Regular';
  src: url('../../fonts/CourierNewPSMT.eot');
  src: url('../../fonts/CourierNewPSMT.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CourierNewPSMT.woff2') format('woff2'),
  url('../../fonts/CourierNewPSMT.woff') format('woff'),
  url('../../fonts/CourierNewPSMT.ttf') format('truetype'),
  url('../../fonts/CourierNewPSMT.svg#CourierNewPSMT') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?a8qy02');
  src:  url('../../fonts/icomoon.eot?a8qy02#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf?a8qy02') format('truetype'),
  url('../../fonts/icomoon.woff?a8qy02') format('woff'),
  url('../../fonts/icomoon.svg?a8qy02#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'FontAwesome';
  src:  url('../../fonts/fontawesome-webfont.eot?a8qy02');
  src:  url('../../fonts/icomoon.eot?a8qy02#iefix') format('embedded-opentype'),
  url('../../fonts/fontawesome-webfont.ttf') format('truetype'),
  url('../../fonts/fontawesome-webfont.woff') format('woff'),
  url('../../fonts/fontawesome-webfont.svg#fontawesome-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="fa-"], [class*=" fa-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FontAwesome' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-user:before {
  content:"\f007";
}
.fa-users:before {
  content:"\f0c0";
}
.fa-trash:before {
  content:"\f014";
}
.fa-money:before{
  content:"\f0d6";
}
.fa-th:before{
  content:"\f00a";
}
.fa-pencil:before{
  content:"\f044";
}
.fa-bar-chart:before{
  content:"\f080";
}
.fa-glass:before{
  content:"\f000";
}
.fa-history:before{
  content:"\f1da";
}
.fa-ban:before {
  content:"\f05e";
}
.fa-eye:before {
  content:"\f06e";
}
.fa-th-list:before {
  content:"\f00b";
}
.fa-file-text:before {
  content:"\f15c";
}
.fa-gbp:before {
  content: "\f154";
}
.fa-user-secret:before {
  content:"\f21b";
}
.fa-ellipsis-v:before {
  content:"\f142";
}
.fa-columns:before {
  content:"\f0db";
}
.fa-credit-card:before {
  content:"\f09d";
}
.fa-step-backward:before {
  content:"\f048";
}
.fa-step-forward:before {
  content:"\f051";
}
.fa-fast-backward:before {
  content:"\f049";
}
.fa-fast-forward:before {
  content:"\f050";
}
.fa-times:before {
  content:"\f00d";
}
.icon-close:before {
  content: "\e914";
}
.icon-dots:before {
  content: "\e915";
}
.icon-activity:before {
  content: "\e900";
}
.icon-addon_cat:before {
  content: "\e901";
}
.icon-addons:before {
  content: "\e902";
}
.icon-back:before {
  content: "\e903";
}
.icon-branches:before {
  content: "\e904";
}
.icon-cog:before {
  content: "\e810;";
}
.icon-alarm:before {
  content: "\e911";
}
.icon-calendar:before {
  content: "\e905";
}
.icon-dashboard:before {
  content: "\e906";
}
.icon-down:before {
  content: "\e907";
}
.icon-downs:before {
  content: "\e908";
}
.icon-drag:before {
  content: "\e909";
}
.icon-foodcat:before {
  content: "\e90a";
}
.icon-foods:before {
  content: "\e90b";
}
.icon-info:before {
  content: "\e90c";
}
.icon-left:before {
  content: "\e90d";
}
.icon-logout:before {
  content: "\e90e";
}
.icon-order:before {
  content: "\e90f";
}
.icon-report:before {
  content: "\e910";
}
.icon-review:before {
  content: "\e911";
}
.icon-right:before {
  content: "\e912";
}
.icon-ups:before {
  content: "\e913";
}
