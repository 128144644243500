//Row Gutters
$grid-gutter-width: 15px;

// Theme ../font family variables




$circular-bold : "Circular-Bold";
$circular-book : "Circular-Book";
$circular-medium : "Circular-Medium";
$courier: "Courier-New-Regular";


// Theme ../Color variables

$theme-color  : #BF9F60;

$white      : #ffffff;

$black      : #000000;

$black1      : #1D1D1D;

$black2      : #1A293B;

$red        : #C60000;

$red1        : #DB2200;

$red2        : #D60052;

$red3        : #EA1F1F;

$gray        : #707070;

$gray1        : #6F6F6F;

$gray2        : #D7D7D7;

$gray3        : #333333;

$gray4       : #868686;

$gray5       : #666666;

$green       : #3DB100;

$green1       : #07B200;

$green3       : #1AE000;

$green4       : #21D600;

$yellow      : #FFB100;

$yellow1      : #FFD800;

$yellow2      : #FFB319;

$purple     : #8700DB;

$blue       : #4757F6;

$blue1       : #051439;

$blue3       : #060F6F;

$blue4       : #0E1031;

$dark-blue       : #161E45;






$light-gray  : #F0F0F0;




$dark-light-gray        : #F4F4F4;

// Theme ../Font size variables

$size1  : 1px;
$size2  : 2px;
$size3  : 3px;
$size4  : 4px;
$size5  : 5px;
$size10 : 10px;
$size12 : 12px;
$size14 : 14px;
$size16 : 16px;
$size18 : 18px;
$size20 : 20px;
$size22 : 22px;
$size24 : 24px;
$size26 : 26px;
$size28 : 28px;
$size30 : 30px;
$size40 : 40px;
$size50 : 50px;
$size60 : 60px;
