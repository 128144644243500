.btn-theme {
  font-size: 15px;
  line-height: 20px;
  font-family: $circular-bold;
  color: $white;
  background: $theme-color;
  min-width: 162px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid $theme-color;
  padding: 9px 25px;
  &.btn-outline {
    background: transparent;
    border: 1px solid #051439;
    border-radius: 50px;
    min-width: 95px;
    font-size: 14px;
    line-height: 19px;
    color: $black2;
    &:hover {
      border-color: $theme-color;
      background: $theme-color;
      color: $white;
    }
  }
  &:hover {
    background: transparent;
    color: $theme-color;
  }
}


