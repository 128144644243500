.main-calander-container{
  overflow-x: scroll;
}
.main-calander-container-wrapper{
  padding-left: 15px;
}
.div_calander_days{
  display:flex;
  border: none;
  width: auto;
}

.div_days {
  border: 1px solid #bbb;
  border-top: none;
  background: #eee;
  padding: 5px;
  flex: 1 0 100px;
  position: absolute;
  width: 100px;
  left: 15px;
  min-height: 64px;
  z-index:2;
}

.div_calander_hrs{
  margin-left: 100px;
  flex: 0 0 auto;
  display: inline-flex;
}

.calander_hr{
  border: 1px solid #bbb;
  border-left: none;
  padding: 5px;
  width:100px;
  text-align: center;
  border-top: none;
  min-width: 100px;
  user-select: none;
}

.div_days p {
  margin-bottom: 10px;
}

.calander_header .div_days,
.calander_header .calander_hr  {
  border-top: 1px solid #bbb;
  min-height: 34px;
}

.selected-calander-div{
  background:#BF9F60 !important;
}

.bg-pax-green{
  background: #c7ffc3;
}
.bg-reserved-pink{
  background:#ffcece;
}

.schedule-date-picker{
  display:flex;
  align-items: center;

  input{
    margin-bottom: 0;
  }
  .div-today-button{
    margin-bottom:24px;
  }
}