body {
  header {
    background: $black1;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 10;
    left: 0;
    padding: 2px 25px;
    box-shadow: 0 1px 5px rgba(0,0,0,.2);
    @media (min-width: 1200px) {
      display: none;
    }
    @media (max-width: 1199px) {
      padding: 10px 15px;
    }


    .navbar {
      padding: 0;
    }
    .brandLogo {
      span {
        font-size: 26px;
        color: $white;
      }
    }
    .navbar-toggle-btn {
      width: 30px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      right: 0;
      display: inline-block;
      transition: all 200ms ease;

      span {
        display: inline-block;
        width: 100%;
        height: 3px;
        background-color: $white;
        position: absolute;
        transition: all 200ms ease;
        left: 0;
        top: calc(50% - 1.5px);
        &:first-child {
          transform-origin: center;
          animation-duration: 600ms;
          animation-fill-mode: forwards;
          animation-name: rotate1;
          transform: translateY(-300%);
        }

        &:last-child {
          transform-origin: center;
          animation-duration: 600ms;
          animation-fill-mode: forwards;
          animation-name: rotate2;
          transform: translateY(300%);
        }
      }
      &.open{
        span {
          &:first-child {
            transform: translateY(0);
            animation-name: rotate-open-1;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            transform: translateY(0);
            animation-name: rotate-open-2;
          }
        }
      }
    }
  }
}





@keyframes rotate1 {
  0% {
    transform: translateY(0) rotate(45deg);

  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: translateY(-300%);
  }
}

@keyframes rotate2 {
  0% {
    transform: translateY(0) rotate(-45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: translateY(300%);
  }
}

@keyframes rotate-open-1 {
  0% {
    transform: translateY(-300%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(-45deg);
  }
}

@keyframes rotate-open-2 {
  0% {
    transform: translateY(300%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}