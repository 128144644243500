* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;

}



@media (min-width: 12000px) {
  .container {
    max-width: 1120px;
  }
}

.row {
  margin-left: -$grid-gutter-width;
  margin-right: -$grid-gutter-width;

  &.no-gutters {
    margin-left: 0;
    margin-right: 0;
  }
}

.row>* {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}


html {
  &.menu-active {
    overflow: hidden;
  }
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  margin: 0;
  overflow-x: hidden;
  font-family: $circular-book;
  font-size: $size16;
  line-height: 21px;
  
  &.active,
  &.overflow-hidden{
    overflow: hidden;
  }
  &.menu-active {
    @media (max-width: 1199px) {
      overflow: hidden;
      &:after {
        content: '';
        background: transparentize($black, .2);
        top: 0;
        left: 280px;
        width: calc(100% - 280px);
        height: 100%;
        position: fixed;
      }
      #root {
        left: 280px;
        position: relative;
      }
      .fixed-footer {
        left: 280px;
      }
    }
  }

  &.sidebar-minimised {
    @media (min-width: 1200px) {
      .sidebar-wrapper {
        width: 60px;
        .sidebar {
          &:after {
            display: none;
          }

          .right-sidebar {
            left: -210px;
            width: 0;
          }
        }
      }
      .content-area-wrapper {
        margin-left: 60px;
        width: calc(100% - 60px);
      }

      .minimise-button {
        left: 47px;
        i {
          transform: rotate(180deg);
        }
      }

      .fixed-footer {
        width: calc(100% - 60px);
      }
    }
  }

}

a {
  text-decoration: none;
  @include transition(0.3s ease all);

  display: inline-block;

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  transition:max-width 0.3s ease-out; // note that
}

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-family: $circular-bold;
   color: $black;
 }



h1 {
  font-size:  $size50 + $size50;
  line-height: 100px;
}

h2 {
  font-size:  31px;
  line-height: 43px;

  @media (max-width: 1439px) {
    font-size: 22px;
    line-height: 26px;
  }
}


h4 {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 30px;
}

h5 {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0;
}

h6 {
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 14px;
}

.form-control:focus {
  outline: none    !important;
  box-shadow: none !important;
  border-color:  #ced4da !important;
}

button:focus {
  outline: none    !important;
  box-shadow: none !important;
}



p {
  font-size: 15px;
  line-height: 21px;
  font-family: $circular-book;
  margin-bottom: 8px;
  strong {
    font-family: $circular-bold;
  }
  & + p {
    margin-top: 12px;
  }
}

body {
  .sidebar-wrapper {
    max-width: 280px;
    width: 280px;
    padding: 0;
    position: fixed;
    left: 0;
    height: 100%;
    top: 0;
    .sidebar {

      &:after {
        content: '';
        background: rgba(0,0,0,0.06);
        width: 1px;
        height: 100%;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }
      .left-sidebar {
        background: $black1;
        max-width: 60px;
        width: 60px;
        min-width: 60px;
        height: 100vh;
        .logo-wrapper {
          text-align: center;
          padding: 15px 0 10px;
          //border-bottom: 1px solid rgba(255,255,255,.17);
          @media (max-width: 1199px) {
            padding-top: 60px;
          }
          a {
            span {
              font-size: 24px;
              color: #fff;
            }
          }
        }
        ul {
          padding: 0;
          list-style: none;
          text-align: center;
          li {
            a {
              color: $white;
              font-size: $size18;
              line-height: 22px;
            }
            & + li {
              margin-top: 8px;
            }
          }
          &.upper-menu {
            margin-top: 20px;
            a {
              font-family: $circular-book;
            }
          }
          &.lower-menu {
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 0;
            li {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              position: relative;
              a {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                span.count-wrapper {
                  background: $red;
                  width: 19px;
                  height: 19px;
                  position: absolute;
                  border-radius: 50%;
                  font-size: 12px;
                  line-height: 12px;
                  top: -7px;
                  right: -8px;
                  text-align: center;
                  span.count {
                    position: relative;
                    top: 3px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
      .right-sidebar {
        max-width: 220px;
        width: 220px;
        height: 100vh;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
        @media (max-width: 1199px) {
          margin-top: 30px;
          height: calc(100vh - 30px);
        }
        .title-block {
          padding: 14px 24px;
          border-bottom: 1px solid rgba(0,0,0,.06);
          position: relative;
          @media (max-width: 1199px) {
            margin-top: 20px;
          }
          p {
            font-family: $circular-bold;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 0;
          }

        }
        ul {
          padding: 0 24px;
          list-style: none;
          margin-top: 20px;
          li {
            position: relative;
            padding-left: 30px;
            &:before {
              position: absolute;
              top:-1px;
              left: 0;
              font-family: 'icomoon';
            }
            a {
              font-size: 18px;
              line-height: 20px;
              color: $black;
              i {
                position: absolute;
                left: 0;
                top: 45%;
                transform: translateY(-50%);
                font-size: 16px;
              }
            }
            & + li {
              margin-top: 14px;
            }
          }

        }
      }


    }
    @media (max-width: 1199px) {
      left: -280px;
      width: 280px;
      height: 100%;
      &.active {
        left: 0;
        z-index: 9;
      }
    }



  }
  .content-area-wrapper {
    padding-bottom: 100px ;
    margin-top: 30px;
    @media (min-width: 1200px) {
      margin-left: 280px;
      width: calc(100% - 280px);
    }
    @media (max-width: 1199px) {
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 60px;
    }
  }
  .minimise-button {
    position: fixed;
    top: 41px;
    left: 267px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(244, 244, 244, .25);
    cursor: pointer;
    i {
      font-size: 12px;
    }
    @media (max-width: 1199px) {
      display: none;
    }
  }
}

/* sidebar responsive */

@media (max-width: 767px) and (orientation: landscape) {
  body .sidebar ul.upper-menu {
    margin-top: 40px;
  }
  body .sidebar ul.lower-menu {
    bottom: 20px;
  }
}



/* form styling */

  .form-group {
    margin-bottom: 24px;
    label {
      font-size: 15px;
      line-height: 21px;
      margin-bottom: 10px;
    }
    .form-control {
      border-radius: 5px;
      border: 1px solid transparentize($gray4, .75);
      height: 48px;
      font-size: 15px;
      &::placeholder {
        color: transparentize($black, .70);
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: transparentize($black, .70);
      }

      &::-ms-input-placeholder {
        color: transparentize($black, .70);
      }
      & + .form-control {
        margin-top: 20px;
      }
    }
    textarea.form-control {
      min-height: 132px;
    }
  }


input:focus, .btn-theme:focus {
  outline: none !important;
  box-shadow: none !important;
}


select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../images/down-arrow.png") no-repeat;
  background-position: calc(100% - 20px);
  padding-right: 40px;
  color: transparentize($black, .7);
}

.input-file-wrapper {
  position: relative;
  text-align: center;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 3px dashed transparentize($gray4, .75);
  &:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid #fff;
  }
  .text-wrapper {
    text-align: center;
    span {
      font-size: 15px;
      color: transparentize($black, .7);
    }
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    z-index: 1;
    cursor: pointer;
  }
}

.add-food-block {
  position: relative;
  .add-food-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
      margin-right: 40px;
    }
    span {
      margin-bottom: 12px;
      a {
        text-decoration: underline;
      }
    }
  }
  .box-outlined {
    padding: 40px;
    @media (max-width: 1199px) {
      padding: 20px;
      margin-bottom: 15px;
    }
    div[class*="col-"] {
      &:nth-last-child(-n+2) {
        @media (min-width: 576px) {
          .form-group {
            margin-bottom: 0;
          }
        }
      }
      &:last-child {
        .form-group {
          margin-bottom: 0;
        }
      }
    }
  }
  .edit-block {
    ul {
      text-align: right;
      padding: 0 20px;
      li {
        display: inline-block;
        a {
          color: $gray4;
        }
        & + li {
          margin-left: 30px;
        }
      }
    }
    @media (min-width: 1200px) {
      position: absolute;
      right: -70px;
      //top: calc(50% + 33px);
      transform: translateY(-50%);
      ul {
        text-align: left;
        padding: 0;
        li {
          display: block;
          & + li {
            margin-top: 12px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

/* message styling */

.message {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $white;
  padding: 5px 15px;
  margin-top: -30px;
  z-index: 0;
  position: sticky;
  top: 0;
  @media (max-width: 991px) {
    margin-top: -21px;
  }
  & + .heading-block {
    margin-top: 10px;
  }
}




/* backgrounds */

.bg-theme {
  background: $theme-color;
}

.bg-blue {
  background: $blue;
}

.bg-purple {
  background: $purple;
}

.bg-yellow {
  background: $yellow2;
}

.bg-red {
  background: $red;
}

.bg-dark-blue {
  background: $dark-blue;
}

.bg-light-green {
  background: #E7FCE7;
}

.bg-light-orange {
  background: #FFF8D5;
}

.bg-light-gray {
  background: #FAFAFA;
}


//margins
body {
  .mt-10 { margin-top: 10px}
  .mt-20 { margin-top: 20px}
  .mt-25 { margin-top: 25px}
  .mt-30 { margin-top: 30px}
  .mt-35 { margin-top: 35px}
  .mt-40 { margin-top: 40px}
  .mt-45 { margin-top: 45px}
  .mt-50 { margin-top: 50px}
  .mt-58 { margin-top: 58px}
  .mt-60 { margin-top: 60px}
  .mt-70 { margin-top: 70px}
  .mt-75 { margin-top: 75px}
  .mt-80 { margin-top: 80px}
  .mt-90 { margin-top: 90px}
  .mt-100 { margin-top: 100px}
  .mt-120 { margin-top: 120px}

  .marb-5 { margin-bottom: 5px !important;}
  .mb-10 { margin-bottom: 10px}
  .mb-20 { margin-bottom: 20px}
  .mb-25 { margin-bottom: 25px}
  .mb-30 { margin-bottom: 30px}
  .mb-45 { margin-bottom: 45px}
  .mb-40 { margin-bottom: 40px}
  .mb-50 { margin-bottom: 50px}
  .mb-58 { margin-bottom: 58px}
  .mb-60 { margin-bottom: 60px}
  .mb-70 { margin-bottom: 70px}
  .mb-75 { margin-bottom: 75px}
  .mb-80 { margin-bottom: 80px}
  .mb-90 { margin-bottom: 90px}
  .mb-100 { margin-bottom: 100px}

  .mt-24 {
    @media (min-width:768px) {
      margin-top: 24px;
    }
  }
  .pb-10 {
    padding-bottom: 10px;
  }
}

.pr-60 {
  @media (min-width: 992px) {
    padding-right: 60px;
  }
}

.pl-50 {
  @media (min-width: 992px) {
    padding-left: 50px;
  }
}

.plr-35 {
  padding: 0 15px;
  @media (min-width: 992px) {
    padding: 0 35px;
  }
}

.plr-50 {
  padding: 0 15px;
  @media (min-width: 992px) {
    padding: 0 50px;
  }
}


.w-960 {
  max-width: 960px;
}


.w-1000 {
  max-width: 1000px;
}

.w-1100 {
  max-width: 1100px;
}

.mr-xl-100 {
  @media (min-width: 1200px) {
    margin-right: 100px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}





.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;


}

.image-block {
  background-size: cover!important;
  background-repeat: no-repeat!important;
  background-position: center!important;
  img {
    opacity: 0;
    width: 100%;
  }
}






/* heading block */

.heading-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  padding: 0 50px;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;



  .title-block {
    display: flex;
    width: auto;
    flex-basis: 0;
    flex-grow: 1;
    @media (max-width: 991px) {
      flex: 0 0 100%;
      margin-bottom: 14px;
    }
    @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
      width: 100%;
    }


    span {
      font-size: 12px;
      line-height: 16px;
      color: rgba(0,0,0,.4);
      & + span {
        &:before {
          content: "/";
          margin: 0 3px;
        }
      }
      a {
        color: rgba(0,0,0,.4);
      }
    }


    .title-block-icon {
      width: 38px;
      height: 38px;
      border: 2px solid $theme-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      span {
        color: $theme-color;
        font-size: 20px;
      }
    }

    .title-block-content {
      h2 {
        font-size: 24px;
        line-height: 28px;
        font-family: $circular-book;
        margin-bottom: 0;
      }
    }


  }


  .calendar-block {
    margin: 0 40px;
    min-width: 290px;
    position: relative;
    @media (max-width: 991px) {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      margin: 0 40px 0 0;
    }
    @media (max-width: 575px) {
      margin: 0;
    }
    @media (max-width: 374px) {
      min-width: auto;
    }
    .form-control {
      height: 42px;
      border-radius: 2px;
      border: 1px solid transparentize(#1C212E, .80);
      padding-right: 35px;
    }
    .icon-calendar {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  .button-wrapper {
    min-width: 162px;
    @media (max-width: 575px) {
      margin-top: 20px;
    }
    .btn-theme {
      width: 100%;
    }
    .dropdown-toggle {
      &:after {
        content: none;
      }
    }
    .dropdown-menu {

      a {
        &:hover {
          font-weight: 600;
          color: $green3;
        }
      }
    }
  }
  @media (max-width: 991px) {
    padding: 0 15px;
  }
  @media (max-width: 575px) {
    display: block;
  }

}




/* modal styling */

.modal-backdrop {
  background-color: $black;
  opacity: .40 !important;
}


body .modal.show {
  display: flex !important;
  height: 100%;
}

body .modal.show .modal-dialog {
  margin: auto ;
  @media (max-width: 575px) {
    margin: auto 15px;
  }
}


.modal {
  .modal-dialog {
    @media (min-width: 767px) {
      max-width: 690px;
      width: 100%;
    }
    .modal-content  {
      border-radius: 0;
    }
    button.close {
      position: absolute;
      top: 25px;
      right: 30px;
      font-size: 14px;
      opacity: 1;
      .icon-close {
        &:before {
          font-weight: bolder;
        }
      }
    }

    .modal-header {
      padding: 19px 30px;
    }
    .modal-body {
      overflow: hidden;
      @media (min-width: 768px) {
        padding: 20px 30px;
      }


    }
  }
}

.single-info{
  margin-bottom: 20px;
  .info-title {
    font-size: 13px;
    line-height: 15px;
    color: $gray5;
    margin-bottom: 8px;
  }
  .info-detail {
    font-size: 16px;
    line-height: 19px;
  }
}

/* custom checkbox */

.form-check {
  margin-top: 8px;
}

input[type="checkbox"] {
  display: none;
  & + label {
    color: transparentize($black,.5);
    padding-left: 12px;
    margin-bottom: 0;
    &:before, &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 5px;
      left: 0;
      top: 0;
      display: block;
      background-color: transparent;
      border: 1px solid transparentize($black, .5);
    }
    &:after {
      background: 0 0;
      transition: all .4s ease-out;
      border-color: transparent;
    }
  }
  &:checked {
    & + label {
      &:after {
        background: $theme-color;
        border-color: $theme-color;
      }
    }
  }
}






/* status color */

.text-theme {
  color: $theme-color;
}

.text-success1 {
  color: #1C9048;
}


.text-success {
  color: #188642 !important;
}

.text-danger {
  color: #BF1212 !important;
}

.text-info {
  color: #61A4E8 !important;
}

.text-info1 {
  color: #2F4FD3;
}

.tag-badge {
  padding: 2px;
  width: 100% !important;
  color: #ffffff;
  border-radius: 5px;
  display: block;
  text-align: center;
  .success {
    background: #188642;
    border: 1px solid #188642;
  }
  .danger {
    background: #BF1212;
    border: 1px solid #BF1212;
  }
  .info {
    background: #61A4E8;
    border: 1px solid #61A4E8;
  }
}


/* filter sidebar */

.filter-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  background: $white;
  z-index: 11;
  border-left: 1px solid $gray;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  @include transition(.3s all ease-in-out);
  opacity: 0;
  visibility: hidden;
  @media (max-width: 1199px) {
    top: 41px;
  }
  &.filter-active {
    opacity: 1;
    visibility: visible;
  }
  .filter-sidebar-wrapper {
    padding: 25px;
    .filter-heading-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      h2 {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 0;
      }
      span {
        font-size: 12px;
        cursor: pointer;
        &:before {
          font-weight: bolder;
          color: $black;
        }
      }
    }
    .filter-form-block {
      height: calc(100vh - 102px);
      overflow-y: scroll;
      @media (max-width: 1199px) {
        height: calc(100vh - 143px);
      }
      .form-group {
        margin-bottom: 20px;
        .form-control {
          height: 40px;
          border-color: transparentize(#1C212E, .59);
        }
      }
      .btn-theme {
        display: block;
        border-radius: 5px;
        font-size: 15px;
        line-height: 20px;
        border-width: 1px;
      }
    }
  }
  .filter-form-block{
    scrollbar-width: none;
  }
  .filter-form-block::-webkit-scrollbar {
    display: none;
  }

  .filter-form-block{
    -ms-overflow-style: none;
  }

}


/* fixed filter */

.fixed-footer {
  text-align: right;
  padding: 30px 60px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 280px);
  background: $white;
  @media (max-width: 1199px) {
    width: 100%;
    left: 0;
    padding: 25px 30px;
  }
}

/*pagination */

ul.pagination {
  justify-content: flex-end;
  margin-bottom: 0;
  li {
    margin: 0 2px;
    &:not(.page-number) {
      background: #f7f7f7;
      a {
        color: $theme-color;
        font-size: 10px;
        padding: 0 14px;
      }
    }
    &.page-info {
      padding: 0 14px;
    }
    a {
      display: block;
      padding: 0 6px;
      font-size: 15px;
      color: $black1;
      &.active {
        color: $theme-color;
        //font-size:20px;
        font-weight: 800;
      }
    }
  }
}

.partner-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}