.item-disabled { pointer-events: none; opacity: .6; cursor: none }
.pointer { cursor: pointer; }
.sortable-list ul li,
li.dragging{
  margin-bottom: 10px;
  border: 1px solid #ccc;
  cursor: move;
  padding: 10px;
  border-radius: 5px;
  list-style: none;
  background: #f6f6f6;
}
.PopConfirm .Pop .message{
  padding:5px;
  margin-top: 5px;
  color: #ff4930;
}
.default-food{
  background: #bf9f60;
  padding: 10px;
  position: absolute;
  top: 30px;
  right: 0;
  border-radius: 5px;
  color: #fff;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
  }
}
.branch-footer{
  margin-left:200px;
}

.table td {vertical-align: middle;}

body .sidebar-wrapper .sidebar .right-sidebar ul {
  padding: 0 0 0 10px;
}

body .sidebar-wrapper .sidebar .right-sidebar ul li a {
  font-size: 16px;
}

.input-file-wrapper + img {
  width: 100%;
}

.gallery-preview {
  position: relative;
  margin: 5px 0 0 0;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
  .order-detail-table .table tbody tr td .product-attributes .single-attribute span {
    font-family: "Circular-Medium";
  }

.sortable-list ul li {
  background: #fafafa;
}

.sortable-list ul li .name-col {
  padding-left: 30px;
}

.sortable-list ul li .name-col span.sort-item-id {
  position: absolute;
  font-size: 60px;
  top: -6px;
  line-height: 0;
  left: 10px;
}
.table-block .table tbody tr td.button-cluster a {
  margin: 3px 0;
}

.svg-img {
  width: auto;
  height: 58px;
}

canvas{
  height:300px !important;
}
//here starts additional
td.buttons-block {
  .dropdown {
    .dropdown-toggle {
      cursor: pointer;
      width: 20px;
      display: block;
      text-align: center;
      &:after {
        display: none;
      }
    }
    .dropdown-menu {
      min-width: -webkit-max-content;
      min-width: -moz-max-content;
      min-width: max-content;
      padding: 10px;
      button{
        &:hover{
          background: #BF9F60;
          color:#fff;
        }
        //border:1px solid #bbb;
        border:none;
        font-size: 14px;
        border-radius: 3px;
        width: 100px;
        margin-bottom: 5px;
        text-align: left;
        background-color: transparent;
      }
    }
  }
}
table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

@media (max-width: 1199px) {
  body.menu-active {
    &.right-sidebar-minimised {
      &:after {
        left: 125px;
        width: calc(100% - 125px);
      }
      #root {
        left: 125px;
      }
    }
  }
}

.heading-block {
  .calendar-block {
    margin-bottom: 10px !important;
  }
  .button-cluster-wrapper {
    a {
      margin-right: 15px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.__react_component_tooltip.show {
  z-index: 1025;
}

.panel-table {
  .table td, .table th {
    border:none;
  }
}

.basic-multi-select {
  .select__control {
    min-height: 48px;
    border: 1px solid hsla(0,0%,52.5%,.25);
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.search-reset-button{
    position: absolute;
    right: 10px;
    bottom: 15px;
    color: #ff0000;
    font-weight: 400;
    font-size: 10px;
}

input[type="date"],[type="month"]
{
  display:block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

#dvPreview .sortable-list ul{
  li{
    flex: 0 0 150px;
    max-width: 150px;
  }
  display: flex;
  flex-wrap:wrap;
  margin:0 -5px;
}

.btn-toggle-status{
  border:none;
  font-size: 14px;
  border-radius: 3px;
}
.timeslot-day-form-error{
  margin-top:-120px;
}

.cursor-default{
  cursor: default;
}