.ErrorTableRow {
  td {
    text-align: center;

    img {
      opacity: .5;
      margin-top: 100px;
      max-width: 200px;
    }

    p {
      margin-bottom: 50px;
    }
  }


}