/* login pages */

.login-pages {
  border-left: 60px solid $black1;
  @media (max-width: 767px) {
    border-width: 30px;
  }
  header {
    display: none;
  }
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 60px;
    height: 100vh;
    background: $black1;
    @media (max-width: 767px) {
      width: 30px;
    }
  }
}

.dashboard-login {
  display: flex;
  height: 100vh;
  @media (max-width: 767px) and (orientation: landscape) {
    display: block;
    height: auto;
    .form-block {
      padding: 60px 0;
    }
  }
}

.form-block {
  margin: auto;
  max-width: 480px;
  padding: 30px 15px;
  width: 100%;
  @media (max-width: 991px) {
    padding: 30px;
  }

  .logo-block {
    margin-bottom: 70px;
    text-align: center;
    img {
      max-width: 360px;
    }
  }
  .btn-theme {
    padding: 12px 25px;
  }
}


.table-block {
  .table {
    margin-bottom: 90px;

    thead {
      tr {
        th {
          font-size: 15px;
          line-height: 21px;
          font-family: $circular-bold;
          padding: 14px;
          position: relative;
          border: none;
          vertical-align: middle;
          span[class*="icon-"] {
            font-size: 10px;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: #FAFAFA;
        }

        td {
          font-size: 15px;
          line-height: 21px;
          font-family: $circular-book;
          padding: 10px 14px;
          border: none;
          vertical-align: middle;
          color: $black;
          height: 68px;
          a:not(.btn-theme) {
            color: inherit;
            text-decoration: underline;
          }
          .icon-drag {
            font-size: 28px;
          }
        }
      }
    }
  }
}

.order-detail-table {
  padding: 10px;
  .table {
    thead {
      tr {
        th {
          border-top: none;
          border-bottom-width: 1px;
          font-size: 15px;
          line-height: 21px;
          font-family: $circular-bold;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 16px;
          line-height: 24px;
          color: $gray3;
          padding: 16px 12px;
          .product-attributes {
            .single-attribute {
              font-size: 13px;
              line-height: 18px;
              span {
                font-family: $circular-bold;
                color: #111111;
              }
              & + .single-attribute {
                margin-top: 14px;
              }
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          text-align: right;
          border-top: none;
          padding: 8px 12px;
          span {
            font-family: $circular-bold;
          }
        }
        &:first-child {
          td {
            border-top: 1px solid #dee2e6;
            padding-top: 25px;
          }
        }
      }
    }
  }
}

/* settings block */

.site-dashboard-links {
  flex: 0 0 200px;
  max-width: 200px;
  position: relative;
  margin-top: 25px;

  @media (max-width: 767px) {
    margin-top: 0;
  }



  ul {
    list-style: none;
    padding-left: 20px;

    li {
      position: relative;
      padding-left: 25px;
      & + li {
        margin-top: 14px;
      }
      a {
        color: $black;
        font-size: 16px;

        &.active, &:hover {
          background: transparent;
          color: $theme-color;
          text-decoration: underline;
          font-weight: 700;
        }
        i {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 30px;
  }
}

.site-dashboard-wrapper {
  flex: 0 0 calc(100% - 200px);
  max-width: calc(100% - 200px);
  margin-top: 25px;
  position: relative;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  &:before {
    position: absolute;
    content: '';
    top: -25px;
    left: 0;
    width: 1px;
    min-height: calc(100vh - 150px);
    height: calc(100% + 25px);
    background: rgba(0, 0, 0, .06);
    @media (max-width: 767px) {
      width: 100%;
      height: 1px;
      min-height: auto;
    }
  }
}


/* b0xes  */

.box-colored {
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  &.box-colored-misc {
    padding: 40px 20px 30px;
  }
}
.overlay-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: -31px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  margin-left: -280px;
}
.overlay .spinner-border {
  width: 12rem;
  height: 12rem;
}

.box-outlined {
  margin-bottom: 30px;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid transparentize($gray,.75);
}

.top-seller-list {
  h6 {
    color: $gray5;
  }
  ul {
    margin-bottom: 0;
    li {
      font-size: 16px;
      line-height: 19px;
      & + li {
        margin-top: 14px;
      }
    }
  }
}






.activity-info {
  position: relative;
  width: 100px;

  .activity-status {
    position: absolute;
    left: 0;
  }
}

.setting-table {
  border-top: 1px solid gray;
  padding-top: 20px;
}

.nav-pills {
  padding-left: 20px;
  border-bottom: 1px solid transparentize($black, .94);
  @media (max-width: 991px) {
    padding-left: 0;
  }

  li.nav-item {
    a.nav-link {
      color: $black;
      padding: 5px 15px;

      &.active {
        font-family: $circular-bold;
        background: transparent;
        position: relative;
        top: -1px;

        &:after {
          content: '';
          background: $black;
          height: 1px;
          position: absolute;
          bottom: 0;
          width: calc(100% - 30px);
          left: 15px;
          right: 15px;
        }
      }
    }
  }
}

.tab-info-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: block;
  }

  .tab-info-block {
    p {
      font-size: 15px;
      line-height: 21px;
    }
  }

  .tab-backup-button {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @media (max-width: 767px) {
      margin-top: 20px;
    }

    .btn-theme {
      min-width: 230px;
      font-family: $circular-bold;
    }
  }
}

.staging-mode {
  .site-dashboard-links {
    ul {
      li {
        a {
          &.active, &:hover {
            color: $yellow2;
          }
        }
      }
    }
  }
  .single-setting {
    .setting-title {
      h2 {
        background: $yellow2;
        &:after {
          background: $yellow2;
        }
      }
    }
    .setting-content {
      .setting-content-wrapper {
        button.dropdown-toggle {
          &[aria-expanded="true"], &:hover {
            background: $yellow2;
            border-color: $yellow2;
          }
        }
      }
    }
  }
}


/* features block */

.features-block {
  margin-top: 15px;
  padding: 0 15px;
  @media (min-width: 992px) {
    padding: 0 60px;
  }
}

.feature-col {
  margin-bottom: 45px;
}

.single-feature {
  text-align: center;
  margin-bottom: 60px;

  img.feature-img {
    height: 51px;
  }

  h3 {
    font-size: 17px;
    line-height: 23px;
    margin: 15px 0 10px;
  }

  p {
    margin-bottom: 20px;
    max-width: 272px;
    margin: 0 auto;
  }

  .btn-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    .btn-theme {
      min-width: 160px;
    }
  }
}




/* box block */

.box-block {
  max-width: 1030px;
  padding: 0 35px;
  @media (max-width: 991px) {
    padding: 0 15px;
  }

  .form-group {
    margin-bottom: 10px;

    label {
      font-family: "Circular-Bold";
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 5px;
      padding: 0 10px;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: url("../../images/down-arrow.png");
      background-repeat: no-repeat;
      background-position: 96% 50%;
    }

    .form-control {
      height: 42px;
    }

    textarea.form-control {
      height: 96px;
    }
  }

  .col-md-12 {
    .form-group {
      select {
        @media (min-width: 768px) {
          background-position: 98% 50%;
        }
      }
    }
  }

  .nav-pills {
    border: none;
    padding: 0;

    li.nav-item {
      margin-right: 15px;
      max-width: 50%;
      @media (max-width: 767px) {
        margin-right: 8px;
      }

      a.nav-link {
        font-size: 16px;
        line-height: 20px;
        border: 1px solid #707070;
        border-radius: 0;
        padding: 15px 10px;
        font-family: "Circular-Bold";
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 16px;
          padding: 10px;
        }

        &.active, &:hover {
          color: #fff;
          background: #4757F6;
          border-color: #4757F6;
          top: 0;

          &:after {
            display: none;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .padded-block {
    padding: 30px 0;
  }

  .wp-password-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    input {
      padding-right: 45px;
    }

    a {
      padding: 0 0 0 10px;
    }

    .copy {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  hr {
    margin: 25px 0;
  }

  &.billing-box-block {
    max-width: none;
  }
}

.billing-details-tab {
  .nav-pills {
    li.nav-item {
      @media (min-width: 992px) {
        width: 33.33%;
      }
      @media (max-width: 991px) {
        flex-basis: 0;
        flex-grow: 1;
      }

      a.nav-link {
        @media (min-width: 768px) {
          font-size: 14px;
          line-height: 17px;
          padding: 11px;
        }

        &.active, &:hover {
          background: $yellow2;
          border-color: $yellow2
        }
      }
    }
  }
}


/* billing block */

.billing-block {
  max-width: 450px;
  padding: 0 15px;
  margin: 60px auto;

  .billing-info-block {
    text-align: center;
    margin-bottom: 35px;
    .billing-price {
      padding: 20px 0;
      h2 {
        font-family: $circular-book;
        @media (min-width: 1440px) {
          font-size: 53px;
          line-height: 56px;
        }
        strong {
          font-family: $circular-bold;
        }
      }
      p {
        @media (min-width: 1439px) {
          font-size: 23px;
          line-height: 26px;
        }
      }
    }
    .billing-features {
      position: relative;
      ul {
        margin: 0;
        padding: 20px 0;
        li {
          font-size: 18px;
          line-height: 20px;
          @media (max-width: 374px) {
            font-size: 15px;
            line-height: 18px;
          }
          & + li {
            margin-top: 6px;
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: $gray;
        top: 0;
        left: -15px;
      }
    }
  }


  .payment-block {
    small {
      color: #666;
    }

    ul.nav-pills {
      padding-left: 0;
      border-bottom: 2px solid rgba(0, 0, 0, .06);

      li.nav-item {
        a.nav-link {
          padding: 10px 5px;
          border: none;

          &.active, &:hover {
            color: inherit;
            background: transparent;
          }

          svg {
            height: 28px;

            .cls-1 {
              fill: #f2f2f2;
            }

            .cls-2 {
              fill: #b3b3b3;
            }
          }

          &.active {
            top: 0;

            &:after {
              width: 100%;
              left: 0;
              right: 0;
              height: 2px;
              bottom: -1px;
              display: block !important;
            }

            svg {
              height: 28px;

              .cls-1 {
                fill: $black1;
              }

              .cls-2 {
                fill: $white;
              }
            }
          }
        }
      }
    }

    .tab-content {
      .payment-details {
        margin-top: 30px;
        box-shadow: 0 20px 50px transparentize($black, .9);
        padding: 30px;
        border-radius: 12px;
        @media (max-width: 374px) {
          padding: 15px;
        }

        p {
          font-size: 12px;
          line-height: 16px;
          font-family: $circular-bold;
          text-transform: uppercase;
          margin-bottom: 0;
        }

        .form-group {
          position: relative;
          margin-bottom: 0;
          margin-top: 20px;

          .floating-label {
            font-size: 12px;
            line-height: 16px;
            color: $gray1;
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: .3s all ease;
            pointer-events: none;
          }

          .form-control {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #E6E6E6;
            padding-left: 0;
            padding-right: 0;
            font-size: 16px;
            z-index: 9;

            &:focus, &:not(:focus):valid {
              & ~ .floating-label {
                top: 0;
              }
            }
          }
        }

        button[type="submit"] {
          font-family: $circular-bold;
        }
      }
    }
  }
}


/* information block */

.information-block {
  max-width: 700px;
  margin: 0 auto;
  padding: 5% 15px;
  text-align: center;

  small {
    font-size: 11px;
    line-height: 17px;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 30px;
    @media (min-width: 1440px) {
      font-size: 42px;
      line-height: 44px;
    }
  }

  p {
    font-size: 16px;
    line-height: 19px;
  }

  .btn-wrapper {
    margin-top: 40px;

    .btn-theme {
      min-width: 162px;
      height: 42px;
      line-height: 40px;
      font-size: 15px;
      @media (max-width: 575px) {
        margin: 0 6px 12px !important;
      }
    }
    .text-blue {
      max-width: 200px;
      display: block;
      margin: 20px auto 0;
      font-family: $circular-bold;
    }
  }
}

.create-staging-block {
  padding: 5% 15px;
  @media (min-width: 1440px) {
    padding: 5% 30px;
    h2 {
      font-size: 45px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
    }
  }
  p {
    & + p {
      margin-top: 24px;
    }
  }
  .btn-wrapper {
    .btn-theme {
      height: 42px;
      line-height: 40px;
      font-size: 15px;
      min-width: 280px;
    }
  }
  .staging-image-block {
    margin-top: 30px;
    @media (min-width: 1200px) {
      text-align: center;
      margin-top: 0;
    }
  }
}


/* pricing banner block */

.pricing-banner-block {
  padding: 10% 12% 10% 4%;
  overflow-x: hidden;
  @media (min-width: 1200px) and (max-width: 1599px) {
    .custom-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .offset-xl-1 {
      margin-left: 0;
    }
    .banner-text-block {
      margin-bottom: 40px;
      text-align: center;

      p {
        margin: 0 auto;
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 10% 30px;
  }

  @media (max-width: 767px) {
    padding: 10% 15px;
  }

  .banner-text-block {
    @media (min-width: 1600px) {
      margin-top: 80px;
    }
    @media (max-width: 1199px) {
      text-align: center;
      margin-bottom: 40px;
    }

    h5 {
      font-size: 20px;
      line-height: 21px;
      font-style: italic;
      margin-bottom: 50px;
      font-family: $circular-book;
      @media (max-width: 991px) {
        margin-bottom: 35px;
      }
    }

    h2 {
      font-size: 43px;
      line-height: 52px;
      font-family: $circular-bold;
      margin-bottom: 50px;
      @media (max-width: 991px) {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 14px;
      line-height: 17px;
      max-width: 550px;
      position: relative;
      z-index: 1;
      @media (max-width: 1199px) {
        margin: 0 auto;
      }
    }
  }

  .packagebox-block {
    position: relative;

    .section-shape {
      position: absolute;
      width: 100%;
      left: 0;
      z-index: -1;

      &[data-placement="top-left"] {
        top: -8%;
        left: -21%;
        @media (max-width: 1599px) {
          top: -10%;
          left: -12%;
        }
        @media (max-width: 767px) {
          top: -2%;
          left: -18%;
        }
      }

      &[data-placement="bottom-left"] {
        bottom: 1%;
        left: -4.5%;
        @media (max-width: 1599px) {
          bottom: 5%;
          left: -3%;
        }
        @media (max-width: 767px) {
          bottom: auto;
          left: auto;
          right: -42%;
          top: 40%;
          transform: translateY(-50%);
        }
      }

      &[data-placement="center-right"] {
        top: 10%;
        right: -65%;
        left: auto;
        @media (max-width: 1599px) {
          top: 10%;
          right: -78%;
        }
        @media (max-width: 767px) {
          top: auto;
          right: auto;
          left: -32%;
          bottom: -7%;
          transform: rotate(60deg);
        }
      }
    }
  }

  .package-box {
    background: $white;
    box-shadow: 0 3px 6px transparentize($black, .84);
    @include border-radius(30px);
    padding: 30px 24px;
    margin-bottom: 30px;

    h2 {
      font-size: 25px;
      line-height: 30px;
      color: $blue3;
    }

    p {
      max-width: 300px;
      font-size: 16px;
      line-height: 26px;
    }

    ul.nav-pills {
      border-bottom: 4px solid $blue4;
      padding-left: 0;

      li.nav-item {
        padding: 0 8px;
        @media (max-width: 991px) {
          padding: 0 4px;
        }

        a.nav-link {
          color: $black;
          font-size: 14px;
          line-height: 16px;
          padding: 6px 8px;
          @media (max-width: 991px) {
            padding: 8px 7px;
            font-size: 17px;
          }

          &.active, &:hover {
            background-color: transparent;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              bottom: -1px;
              left: 0;
              background: $blue;
            }
          }
        }
      }
    }

    .tab-content {
      .tab-pane-wrapper {
        .price-block {
          h2 {
            font-size: 62px;
            line-height: 70px;
            color: $black;
            margin-bottom: 0;

            span {
              color: $blue;
            }
          }

          p {
            margin-bottom: 4px;
          }
        }

        .package-features {
          ul {
            margin-bottom: 0;

            li {
              position: relative;
              padding-left: 24px;
              margin-bottom: 5px;

              &:before {
                position: absolute;
                content: '\e913';
                font-family: 'icomoon' !important;
                top: 1px;
                left: 0;
                color: inherit;
              }
            }
          }
        }
      }
    }

    &.big-box {
      background: $blue4;

      ul.nav-pills {
        border-bottom: 4px solid $white;

        li.nav-item {
          a.nav-link {
            color: $white;
          }
        }
      }

      .tab-content {
        .tab-pane-wrapper {
          .price-block {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;

            h2 {
              margin-right: 30px;
              color: $white;
            }
          }

          .package-features {
            ul {
              column-count: 2;
              @media (max-width: 575px) {
                column-count: 1;
              }
            }
          }
        }
      }

      * {
        color: $white;
      }
    }
  }
}

.has-error {
  input,
  select {
    border: 1px solid $red !important;
  }
  .label-text{color:$red}
}
/*additional */
.gallery-preview {
  position: relative;
  margin: 5px;
  width: calc(50% - 10px);
  display: inline-block;
  vertical-align: top;
}



.gallery-preview img {
  width: 100%;
}



.gallery-preview i {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/*account form css */
.account-block {
  max-width: 1200px;
}
@media (max-width: 991px) {
  .account-block {
    padding: 0 15px;
  }
}
@media (min-width: 576px) {
  .account-block-wrapper {
    display: flex;
    flex-direction: row;
  }
}
.account-block-wrapper .account-holder-img {
  width: 140px;
  height: 140px;
  min-width: 140px;
  margin-top: 30px;
}
.account-block-wrapper .account-holder-img img {
  border-radius: 50%;
}
.account-block-wrapper .account-info {
  width: 100%;
  padding-left: 30px;
}
@media (max-width: 575px) {
  .account-block-wrapper .account-info {
    padding-left: 0;
  }
}
.account-block-wrapper .account-info .btn-outline {
  margin-top: 20px;
}
.account-block-wrapper .account-info .btn-submit {
  margin-top: 20px;
}
.account-block-wrapper .account-info textarea {
  min-height: 95px;
}
.account-block-wrapper .account-info label {
  margin-top: 20px;
}
.account-block-wrapper .account-info .form-group {
  margin-bottom: 0;
}
.account-block-wrapper.variation-bottom-block {
  padding: 15px 15px 50px;
}
.account-block-wrapper.variation-bottom-block .account-holder-img {
  width: 122px;
  height: 122px;
  min-width: 122px;
}
.account-block-wrapper.variation-bottom-block .account-holder-img img {
  border-radius: 0;
}
.account-block-wrapper .btn-rounded {
  padding-top: 13px;
  padding-bottom: 13px;
}
.btn-theme.btn-rounded {
  border-radius: 8px;
  border-color: #707070;
}
.modal .modal-dialog {
  width: 100%;
}

/*additional css startst here */


body.right-sidebar-minimised  .sidebar-wrapper {
  max-width: 125px;
  width: 125px;
}
body.right-sidebar-minimised  .sidebar-wrapper .sidebar .right-sidebar {
  max-width: 65px;
  width: 65px;
}



body.right-sidebar-minimised  .sidebar-wrapper .sidebar .right-sidebar .title-block {
  min-height: 55px;
}
body.right-sidebar-minimised  .sidebar-wrapper .sidebar .right-sidebar .title-block p {
  display: none;
}


.box-outlined-wrapper {


  position: relative;


}

body.right-sidebar-minimised  .minimise-button {
  left: 112px;
}
@media (min-width: 1200px) {
  body.right-sidebar-minimised  .content-area-wrapper {
    margin-left: 125px;
    width: calc(100% - 125px) !important;
  }
  body.sidebar-minimised.right-sidebar-minimised .sidebar-wrapper {
    width: 60px;
  }
  body.sidebar-minimised.right-sidebar-minimised .minimise-button {
    left: 47px;
  }
  body.sidebar-minimised.right-sidebar-minimised .content-area-wrapper {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
  .add-food-block .edit-block {
    top: calc(50% + 25px);
  }
}
body .sidebar-wrapper .sidebar .right-sidebar ul li a.active, body .sidebar-wrapper .sidebar .right-sidebar ul li a:hover {
  color: #bf9f60;
  text-decoration: underline;
  font-weight: 700;
}