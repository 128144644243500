.NotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    opacity: .5;
    margin-top: 100px;
  }

  p {
    margin-bottom: 50px;
  }
}