.calander-date-time {
  position: relative;
}



.CalanderInfo {
  transition: all .3s ease;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  border: 1px solid #ece5f7;
  width: 250px;
  top: 0;
  left: 75%;
  background: #fff;
  z-index: 3;
  user-select:unset;
}



.CalanderInfo:before {
  content: '';
  border: none;
  border-left: 15px solid #fff;
  border-top: 15px solid #fff;
  display: block;
  transform: rotate(45deg);
  position: absolute;
  left: -8px;
  top: 10px;
  z-index: 2;
}



.CalanderInfo:after {
  content: '';
  border: none;
  border-left: 15px solid rgba(187,187,187,.5);
  border-top: 15px solid rgba(187,187,187,.5);
  display: block;
  transform: rotate(45deg);
  position: absolute;
  left: -9px;
  top: 10px;
  z-index: 1;
}



.container-info{
  transition: all .3s ease;
  padding: 10px;
  opacity: 1;
  border-radius: 5px;
  font-size: 12px;
  cursor: default;
}
.div-info-box{
  border-bottom: 1px solid #bbbbbb;
}
.mt-10{
  margin-top:10px;
}
.div-info-close{
  position: absolute;
  font-weight: 700;
  font-size: 14px;
  top: 3px;
  right: 7px;
  z-index: 9;
}

.calander_hr.pointer {
  cursor: pointer;
}