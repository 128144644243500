.unmapped-wrapper, .mapped-wrapper{
  border: 1px solid #eee;
  padding: 10px;
width: 500px;
  li{
    list-style:none;
  }
}
.category-wrapper, .mapped-wrapper-grid{
  display: flex;
}

.mapped-wrapper-grid label{
  width: 400px;
}
.category-remove{
  font-size: 22px;
    color: #BF9F60;
    cursor: pointer;
}